/*******************************************
 * 
 * Main Files
 * Include all files and dependencies. 
 * PS: Don't Write rule here.
 * 
 ******************************************/

//Variables and configuration
@import 'modules/variables';

//Reboot
@import 'modules/mixins';
@import 'modules/normalize';
@import 'modules/reboot';

//Vendors 
// @import 'vendors/font-awesome/font-awesome';
@import 'vendors/slick/slick';
@import 'vendors/slick/slick-theme';

//Generic Helpers
@import 'modules/utilities/helpers';

//CSS3 Mixin Helpers
@import 'modules/utilities/css3-mixins';

//SCSS Base
@import 'modules/base';

//Gravity Form Reset
@import 'modules/utilities/gravity-forms-reset';

//Template
@import 'template/header';
@import 'template/footer';
@import 'template/BBhead';
@import 'template/sections';