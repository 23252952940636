/*******************************************
 *
 * Common Classes
 *
 ******************************************/

//body
body,p,div,span,section,td,h1,h2,h3,h4,h5,h6{
  font-family: 'Lato', sans-serif;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

section{
  position: relative;
}

main{
  margin-top: 100px;
  @include media-breakpoint-down(md){
    margin-top: 65px;
  }
}

.unique{
  font-size: 36px;
  @include media-breakpoint-down(sm){
    font-size: 18px;
  }
}

.uniqueImg{
  max-width: 100%;
  height: auto !important;
  @include media-breakpoint-down(sm){
    width: 104px;
  }
}


.anim{
  transform: translateY(100px) scale(0.5) !important;
  opacity: 0 !important;
  transition-duration: 0.7s;
  &.visible{
      transform: none !important;
      opacity: 1 !important;
  }
}





.tit{
  font-weight: 900;
  font-style: italic;
  font-size: 36px;
}


.arrowSlick{
  position: absolute;
  top: 50%;
  width: 63px;
  height: 15px;
  transform-origin: center;
  &.Aprev{
    left: 50px;
    transform: rotate(180deg);
  }
  &.Anext{
    right: 50px;
  }
}