.img_full_width{
    overflow: hidden;
    .galleryItem{
        height: 410px;
        margin: 0;
        display: block !important;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(sm){
            height: 235px;
        }
    }
}

.home{
    .img_full_width{
        .galleryItem{
            height: 550px;
            @include media-breakpoint-down(sm){
                height: 300px;
            }
        }
    }
}

.one_img_full_width{
    img{
        width: 100%;
        height: auto;
    }
}



.txt_content{
    background-color: $BBblu;
    padding-top: 80px;
    padding-bottom: 80px;
    p{
        color: #fff;
        a{
            background-color: #fff !important;
            padding: 6px 12px 6px 12px;
            color: $BBblu;
            display: inline-block;
            margin-top: 10px;
            margin-bottom: 12px;
            font-weight: 700;
        }
    }
    .voce{
        border-bottom: 2px solid rgba($BByellow,0.1);
        padding-top: 29px;
        padding-bottom: 14px;
        p{
            color: $BByellow;
            font-size: 22px;
            font-weight: 900;
            font-style: italic;
            text-transform: uppercase;
            @include media-breakpoint-down(xs){
                font-size: 18px;
            }
        }
        &.destra{
            text-align: right;
            padding-right: 35px;
            ::after{
                content: '';
                position: absolute;
                top: calc(50% - 5px);
                right: 15px;
                width: 20px;
                height: 20px;
                background-image: url(../../media/images/arrowYellow.svg);
                // transform: rotate(180deg);
            }
        }
        &.sinistra{
            text-align: left;
            padding-left: 35px;
            ::before{
                content: '';
                position: absolute;
                top: calc(50% - 5px);
                left: 15px;
                width: 20px;
                height: 20px;
                background-image: url(../../media/images/arrowYellow.svg);
            }
        }
    }
    .logo_testo{
        padding-bottom: 35px;
        img{
            width: 130px;
            height: auto;
        }
    }
}



.yt_video{
    background-color: $BBblu;
    height: 574px;
    @include media-breakpoint-down(md){
        height: 350px;
    }
    @include media-breakpoint-down(sm){
        height: 200px;
    }
}

.yt_video_full{
    height: 680px;
    @include media-breakpoint-down(lg){
        height: 574px;
    }
    @include media-breakpoint-down(md){
        height: 350px;
    }
    @include media-breakpoint-down(sm){
        height: 280px;
    }
    @include media-breakpoint-down(xs){
        height: 200px;
    }
}

.googleMapSection{
    background-color: $BBblu;
    height: 574px;
    @include media-breakpoint-down(md){
        height: 450px;
    }
    @include media-breakpoint-down(sm){
        height: 300px;
    }
}



.form_chiaro{
    background-color: #fff;
    padding-top: 90px;
    padding-bottom: 90px;
    .tit{
        color: $BBblu;
    }
    .formContainer{
        margin-top: 28px;
    }
    .gfield_label{
        display: none;
    }
    .half{
        width: 50%;
        display: inline-block;
        @include media-breakpoint-down(sm){
            width: 100%;
        }
    }
    input:not(input[type='checkbox']), textarea{
        padding: 10px;
        padding-left: 14px;
        width: 100% !important;
        background-color: #F5F5F7;
        border-style: none;
        outline: none !important;
        color: $BBblu;
        font-size: 14px;
        &:focus{
            outline: none !important;
        }
    }
    input[type='submit']{
        background-color: $BBblu !important;
        color: #fff !important;
        font-size: 18;
        font-weight: 700;
    }
    ::-webkit-input-placeholder {
        color: $BBblu;
    }
    :-ms-input-placeholder {
        color: $BBblu;
    }
    ::placeholder {
        color: $BBblu;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px #F5F5F7 inset !important;
    }
    label{
        transform: translateY(-3px);
    }
    label, .gfield_html{
        color: $BBblu;
        font-size: 12px;
        padding-bottom: 0px;
        a{
            color: $BBblu !important;
            text-decoration: underline !important;
        }
    }
    //validation
    .validation_error{
        color: #f00 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    li.gfield.gfield_error{
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        background-color: transparent !important;
    }
    .validation_message{
        color: #f00 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        margin-top: 0px !important;
    }
    //confirmation
    .gform_confirmation_message{
        font-size: 16px;
        color: $BBblu;
        text-align: center;
    }

    .formContacts{
        a{
            color: $BBblu;
            font-size: 14px;
        }
    }
    .formSocials{
        a{
            color: $BBblu;
            margin-left: 6px;
            font-size: 16px;
            @include media-breakpoint-down(sm){
                margin-left: 0px;
                margin-right: 6px;
            }
        }
    }
}



.form_scuro{
    background-color: $BBblu;
    padding-top: 90px;
    padding-bottom: 45px;
    .borderBottom{
        padding-bottom: 45px;
        border-bottom: 2px solid rgba(255,255,255,0.1);
    }
    .tit{
        color: #fff;
    }
    .formContainer{
        margin-top: 28px;
    }
    .gfield_label{
        display: none;
    }
    .half{
        width: 50%;
        display: inline-block;
        @include media-breakpoint-down(sm){
            width: 100%;
        }
    }
    input:not(input[type='checkbox']), textarea{
        padding: 10px;
        padding-left: 14px;
        width: 100% !important;
        background-color: transparent;
        border-style: none;
        border-bottom: 2px solid rgba(255,255,255,0.1);
        outline: none !important;
        color: #fff;
        font-size: 14px;
        &:focus{
            outline: none !important;
        }
    }
    input[type='submit']{
        background-color: $BBblu !important;
        border: 1px solid #fff !important;
        color: #fff !important;
        font-size: 18;
        font-weight: 700;
    }
    ::-webkit-input-placeholder {
        color: #fff;
    }
    :-ms-input-placeholder {
        color: #fff;
    }
    ::placeholder {
        color: #fff;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px $BBblu inset !important;
    }
    label{
        transform: translateY(-3px);
    }
    label, .gfield_html{
        color: #fff;
        font-size: 12px;
        padding-bottom: 0px;
        a{
            color: #fff !important;
            text-decoration: underline !important;
        }
    }
    //validation
    .validation_error{
        color: #f00 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    li.gfield.gfield_error{
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        background-color: transparent !important;
    }
    .validation_message{
        color: #f00 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        margin-top: 0px !important;
    }
    //confirmation
    .gform_confirmation_message{
        font-size: 16px;
        color: #fff;
        text-align: center;
    }

    .formContacts{
        a{
            color: #fff;
            font-size: 14px;
        }
    }
    .formSocials{
        a{
            color: #fff;
            margin-left: 6px;
            font-size: 16px;
            @include media-breakpoint-down(sm){
                margin-left: 0px;
                margin-right: 6px;
            }
        }
    }
}

.galleryBlock{
    position: relative;
}

.gallery_grid{
    padding-top: 45px;
    padding-bottom: 45px;
    @include media-breakpoint-down(sm){
        padding-top: 35px;
        padding-bottom: 35px;
    }
    .MPgallery{
        .galleryItem{
            width: calc(25% - 30px );
            float: left;
            margin: 15px;
            padding-top: calc(25% - 30px );
            background-size: cover;
            background-position: center;
        }
    }
    .slideGallery{
        overflow: hidden;
        padding-left: 35px;
        padding-right: 35px;
        .galleryItem{
            width: calc(100%);
            padding-top: 100%;
            background-size: cover;
            background-position: center;
        }
    }
}

.gallery_txt{
    padding-bottom: 45px;
    // padding-top: 45px;
    background-color: $BBblu;
    @include media-breakpoint-down(sm){
        padding-bottom: 35px;
        // padding-top: 35px;
    }
    .MPgallery{
        .galleryItem{
            float: left;
            margin: 15px;
            width: calc(50% - 30px );
            padding-top: calc(50% - 30px );
            background-size: cover;
            background-position: center;
            @include media-breakpoint-down(sm){
                width: calc(100% - 30px );
                padding-top: calc(100% - 30px );
            }
            position: relative;
            .tit{
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                width: 100%;
                text-align: center;
            }
        }
    }
}


.contatti{
    padding-top: 10px;
    background-color: $BBblu;
    a{
        color: #fff;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
    .titContatti{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
    }
}





.footerFooter{
    position: relative;
    background-color: $BBblu;
}