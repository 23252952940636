.header{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100px;
    background-color: #fff;
    z-index: 10;
    transition-duration: 0.7s;
    @include media-breakpoint-down(md){
        height: 55px;
    }
    .upBar{
        height: 100px;
        @include media-breakpoint-down(md){
            height: 65px;
        }
        .menuBtnContainer{
            position: absolute;
            top: 50%;
            left: 15px;
            width: 35px;
            height: 35px;
            transform: translateY(-50%);
            cursor: pointer;
            // background-color: rgba(255,255,255,0.08);
            .linetop{
                position: absolute;
                top: 0px;
                left: 0px;
                width: 35px;
                height: 2px;
                background-color: $BBblu;
                // background-color: #fff;
                transform: translateY(9px);
                transition-duration: 0.3s;
            }
            .linebottom{
                position: absolute;
                top: 0px;
                left: 0px;
                width: 24px;
                height: 2px;
                background-color: $BBblu;
                // background-color: #fff;
                transform: translateY(24px);
                transition-duration: 0.3s;
            }
        }
        .langSelector{
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                li{
                    display: inline-block;
                    a{
                        padding: 0;
                        color: $BBblu !important;
                        text-transform: uppercase;
                        transition-duration: 0.3s;
                        &:hover{
                            text-decoration: none;
                            text-transform: uppercase;
                            opacity: 0.6;
                        }
                        span{
                            color: $BBblu !important;
                            font-size: 16px;
                            @include media-breakpoint-down(xs){
                                font-size: 12px;
                            }
                            &::after{
                                content:'/';
                            }
                        }
                    }
                    &:nth-last-child(1){
                        span{
                            &::after{
                                content:'';
                            }
                        }
                    }
                }
            }
        }
        .logo{
            position: absolute;
            width: 300px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            transition-duration: 0.5s;
            @include media-breakpoint-down(md){
                width: 240px;
            }
            @include media-breakpoint-down(sm){
                width: 170px;
            }
        }
    }
    .headerFooter{
        position: absolute;
        bottom: 0px;
        left: 0px;  
        width: 100%;
        opacity: 0;
        pointer-events: none;
        transition-duration: 0.5s;
        p{
            color: $BBblu;
            a{
                color: $BBblu !important;
            }
        }
    }
    nav{
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 100%);
        opacity: 0;
        pointer-events: none;
        transition-delay: 0s;
        transition-duration: 0.5s;
        @include media-breakpoint-down(xs){
            text-align: left;
            top: 110px;
            left: 30px;
            transform: none;
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                display: inline-block;
                margin: 30px;
                transition-duration: 0.2s;
                @include media-breakpoint-down(md){
                    margin: 20px;
                }
                @include media-breakpoint-down(xs){
                    display: block;
                }
                a{
                    color: $BBblu;
                    font-weight: 900;
                    font-size: 25px;
                    font-style: italic;
                    text-transform: none;
                    text-decoration: none;
                }
                &:hover{
                    transform: scale(1.28);
                    opacity: 0.6;
                }
            }
        }
    }
    &.open{
        height: 100%;
        .linetop{
            transform: rotate(45deg) translate(11px,11px) !important;
        }
        .linebottom{
            width: 35px !important;
            transform: rotate(-45deg) translate(-11px,11px) !important;
        }
        .logo{
            top: 0px;
            width: 400px;
            transform: translate(-50%,95px);
            @include media-breakpoint-down(md){
                width: 300px;
                transform: translate(-50%,75px);
            }
            @include media-breakpoint-down(sm){
                width: 200px;
                transform: translate(-50%,59px);
            }
        }
        .headerFooter{
            opacity: 1;
            pointer-events: auto;
        }
        nav{
            opacity: 1;
            transition-delay: 0.3s;
            transform: translate(-50%, -50%);
            pointer-events: auto;
            @include media-breakpoint-down(xs){
                transform: none;
            }
        }
    }
}