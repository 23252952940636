.BBhead{
    height: 132px;
    background-color: #fff;
    @include media-breakpoint-down(sm){
        height: 55px;
    }
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include media-breakpoint-down(sm){
            max-width: calc(100% - 20px);
            width: 300px;
        }
    }
}