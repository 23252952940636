.footer{
    p{
        color: #fff;
        font-size: 8px;
        text-align: center;
        a{
            text-transform: none;
            color: #fff !important;
            font-size: 8px !important;
        }
    }
}